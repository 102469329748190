import React from "react";
import ReactDOM from "react-dom";
import { v4 } from "uuid";
import pubSub from "pubsub-js";
import { isArray } from "./helpers/javascript";
import { fireEvent, sendMessagesToParent } from "./util";
import { initEvents } from "./events";

// Configure basic namespaces
window.FC = window.FC || {};
window.MC = window.MC || {};
window.MC.Loader = window.MC.Loader || {};
window.MC.Pages = window.MC.Pages || {};
window.MC.Pages.Components = window.MC.Pages.Components || [];
window.MC.Pages.LoadedComponents = window.MC.Pages.LoadedComponents || [];
window.MC.Pages.Definitions = window.MC.Pages.Definitions || {};
window.MC.Pages.__fcRuntime = window.MC.Pages.__fcRuntime || {};
window.MC.Pages.__fcRuntime.bundles = window.MC.Pages.__fcRuntime.bundles || {};
window.MC.Pages.__fcRuntime.styles = window.MC.Pages.__fcRuntime.styles || {};
window.MC.Pages.__fcRuntime.__cmpSettingsQueue =
  window.MC.Pages.__fcRuntime.__cmpSettingsQueue || [];
window.MC.USrc = window.MC.USrc || {};
window.MC.USrc.Parent = window.MC.USrc.Parent || {};
window.MC.Vendors = window.MC.Vendors || {};
window.MC.Vendors.PubSub = pubSub;
window.EM = window.EM || {};
window.EM.___fcRuntime = window.EM.___fcRuntime || {};
window.EM.___fcRuntime.vendors = window.EM.___fcRuntime.vendors || {};
window.EM.___fcRuntime.vendors.React16 = React;
window.EM.___fcRuntime.vendors.ReactDOM16 = ReactDOM;

// Init events
initEvents({});

(() => {
  // Update parent id
  const updateParentId = () => {
    const cmpId = window.location.hash
      ? window.location.hash.substring(1)
      : `mission-control-component-${v4()}`;
    window.MC.USrc.Parent.cpmId = window.MC.USrc.Parent.cpmId || cmpId;
    document.body.setAttribute("data-mc-iframe", window.MC.USrc.Parent.cpmId);
  };
  // Retrieve component container
  const retrieveContainer = (cmpId, noLoaded) => {
    const container = document.querySelector("[data-mc-cmp-id=" + cmpId + "]");
    if (container && noLoaded && container.getAttribute("data-mc-loaded"))
      return null;
    return container || null;
  };

  // Render react component definition into container
  const render = (component, definition) => {
    const container = retrieveContainer(component.cmpId);
    if (container) {
      const callback = () => {
        container.setAttribute("data-mc-loaded", !0);
        fireEvent("mc-rendered-component");
      };
      ReactDOM.render(
        React.createElement(definition, {
          cmpConfig: component,
          ref: callback,
        }),
        container
      );
    }
  };

  // Execute component definition to allow render it
  const executeDefinition = (component) => {
    let componentDefinition = window.MC.Pages.Definitions[component.scriptUrl];
    if (
      !componentDefinition &&
      window.EM &&
      window.EM.___fcRuntime &&
      isArray(window.EM.___fcRuntime.___cmpDefinitionsQueue) &&
      window.EM.___fcRuntime.___cmpDefinitionsQueue.length
    ) {
      componentDefinition = window.EM.___fcRuntime.___cmpDefinitionsQueue.pop();
      window.MC.Pages.Definitions[component.scriptUrl] = componentDefinition;
    }
    if (
      componentDefinition &&
      typeof componentDefinition.definition === "function"
    ) {
      window.MC.Pages.__fcRuntime.__cmpSettingsQueue.push(component);
      componentDefinition.definition();
    }
  };

  // Append any element to DOM
  const appendChild = (element, props, parent) =>
    new Promise((resolve, reject) => {
      const el = document.createElement(element);
      Object.keys(props).forEach((key) => {
        el.setAttribute(key, props[key]);
      });
      if (parent) parent.appendChild(el);
      else document.body.appendChild(el);
      resolve(el);
    });

  // Run preload custom props, for MC standard, allow run component initial population.
  const preloadCustomProps = (settings, hooks) => {
    return new Promise(function (resolve, reject) {
      try {
        if (hooks && typeof hooks.preloadCustomProps === "function")
          resolve(hooks.preloadCustomProps(settings));
      } catch (error) {
        reject(error);
      }
    });
  };
  // Apply MC standard to component settings
  const settingsBuilder = (defaultSettings) => {
    return new Promise((resolve, reject) => {
      const newSettings = Object.assign(
        {},
        window.MC.Pages.__fcRuntime.__cmpSettingsQueue.shift()
      );
      newSettings.context = window.MC.Pages.Context;
      newSettings.containerConfig = newSettings.settings.containerConfig;
      newSettings.globalSettings = window.MC.Pages.GlobalSettings;
      newSettings.servicesConfig = {
        labels: {
          labels: window.MC.Pages.Labels,
        },
      };
      newSettings.containerConfig.settings.instance =
        defaultSettings.containerConfig.settings.instance;
      delete newSettings.settings;
      resolve(newSettings);
    });
  };
  // Register component into ComponentStack for print into screen
  // TODO: Namespace FC must be changed by MC, need change in bundles.
  window.FC.registerComponent = (definition, defaultSettings) => {
    settingsBuilder(defaultSettings)
      .then((settings) => {
        return preloadCustomProps(settings, defaultSettings.hooks);
      })
      .then((settings) => {
        render(settings, definition);
      })
      .catch((error) => {});
  };

  // Append script once time in dom
  const appendUniqueScript = (url) => {
    return new Promise((resolve) => {
      if (
        !window.MC.Pages.__fcRuntime.bundles[url] &&
        !document.querySelector(`script[src="${url}"]`)
      ) {
        appendChild("script", {
          type: "text/javascript",
          async: !0,
          src: url,
          "data-mc-bundle": !0,
        }).then((el) => {
          el.onload = () => {
            resolve();
          };
        });
        window.MC.Pages.__fcRuntime.bundles[url] = true;
      } else resolve();
    });
  };

  // Append style once time in dom
  const appendUniqueStyle = (url) => {
    return new Promise((resolve, reject) => {
      if (
        !window.MC.Pages.__fcRuntime.styles[url] &&
        !document.querySelector(`link[href="${url}"]`)
      ) {
        appendChild(
          "link",
          {
            rel: "stylesheet",
            media: "nope!",
            onload: "this.media='all'",
            "data-mc-style": !0,
            href: url,
          },
          document.head
        );
        window.MC.Pages.__fcRuntime.styles[url] = true;
      } else resolve();
    });
  };

  // Create elements for component's styles
  const styles = (url, abstractCSS) => {
    if (abstractCSS) {
      const baseStyle = window.document.createElement("style");
      baseStyle.type = "text/css";
      if (baseStyle.styleSheet) baseStyle.styleSheet.cssText = abstractCSS;
      else baseStyle.appendChild(document.createTextNode(abstractCSS));
      window.document.head.appendChild(baseStyle);
      if (window.parent && window.parent !== window)
        window.document.body.style.overflow = "hidden";
    } else if (url) {
      const styles = url.split(",");
      if (isArray(styles)) {
        styles.forEach((u) => {
          const basicUrl = !u.endsWith(".css") ? u + ".css" : u;
          appendUniqueStyle(basicUrl);
        });
      }
    }
  };

  // Create elements for component's scripts
  const script = (component) =>
    appendUniqueScript(component.scriptUrl).then(() =>
      executeDefinition(component)
    );

  // Load component bundle and css styles
  const load = (component) => {
    if (component.type !== "iframe") {
      styles(component.styles, component.abstractCSS);
      script(component);
    }
  };

  // Prepare components to begin load
  const prepare = () => {
    if (window.MC.Pages.Components.length) {
      const container = retrieveContainer(
        window.MC.Pages.Components[0].cmpId,
        true
      );
      if (container) {
        const component = window.MC.Pages.Components.shift();
        setTimeout(() => {
          window.MC.Vendors.PubSub.publish("tracking.mc", {
            component: component.name,
            cmpId: component.cmpId,
            version: component.version,
            moduleId: component.moduleId,
            action: "impression",
            gclid: component.gclid,
            social: component.social,
          });
        }, 2000);
        window.MC.Pages.LoadedComponents.push(component);
        load({ ...component });
      }
    }
  };

  // Create a component container for render into
  const createComponentDeclaration = (cmpConfig) => {
    if (
      !document.querySelector("div[data-mc-cmp-id='" + cmpConfig.cmpId + "']")
    ) {
      let parent =
        (cmpConfig.container && cmpConfig.container.element) ||
        document.querySelector("[data-page-container]");
      if (
        cmpConfig.container &&
        cmpConfig.container.selector &&
        document.querySelector(cmpConfig.container.selector)
      )
        parent = document.querySelector(cmpConfig.container.selector);
      if (!parent) parent = document.body;
      const containerElement = document.createElement("article");
      const iDiv = document.createElement("div");
      iDiv.setAttribute("data-mc-cmp-id", cmpConfig.cmpId);
      if (cmpConfig.name) iDiv.setAttribute("data-mc-cmp", cmpConfig.name);
      if (cmpConfig.version)
        iDiv.setAttribute("data-mc-version", cmpConfig.version);
      containerElement.appendChild(iDiv);
      if (cmpConfig.container && cmpConfig.container.next)
        parent.insertBefore(containerElement, cmpConfig.container.next);
      else parent.appendChild(containerElement);
    }
  };

  // Init loader to begin load components
  window.MC.Loader.init = () => {
    if (
      window.MC &&
      window.MC.Pages &&
      Array.isArray(window.MC.Pages.Components)
    ) {
      window.MC.Pages.Components.forEach((component) => {
        createComponentDeclaration(component);
        try {
          if (component.lightboxSrc)
            sendMessagesToParent("append-lightbox", {
              cmpId: window.MC.USrc.Parent.cpmId,
              lightboxSrc: component.lightboxSrc,
            });
        } catch (e) {}
      });
      prepare();
    }
  };
  updateParentId();
  window.MC.Loader.init();
})();
